export const SET_TORRENT_SUCCESS = 'SET_TORRENT_SUCCESS';
export const SET_PWD_SUCCESS = 'SET_PWD_SUCCESS';
export const SET_FILE_SUCCESS = 'SET_FILE_SUCCESS';
export const SET_SOURCE = 'SET_SOURCE';
export const SET_PLAYER_STATUS_SUCCESS = 'SET_PLAYER_STATUS_SUCCESS';
export const SET_PLAYER_FILE_PATH_SUCCESS = 'SET_PLAYER_FILE_PATH_SUCCESS';
export const SET_PLAYER_SOURCE_URL_SUCCESS = 'SET_PLAYER_SOURCE_URL_SUCCESS';
export const SET_TORRENT_FAILURE = 'SET_TORRENT_FAILURE';
export const CLEAR_TORRENT_LOAD_ERROR = 'CLEAR_TORRENT_LOAD_ERROR';
export const SET_STAT_SUCCESS = 'SET_STAT_SUCCESS';
export const SET_LOAD_STATE = 'SET_LOAD_STATE';
export const SET_LOAD_STATE_SUCCESS = 'SET_LOAD_STATE_SUCCESS';
export const SET_LOAD_STATE_FAILURE = 'SET_LOAD_STATE_FAILURE';
export const SET_DELIVERY_FAILURE = 'SET_DELIVERY_FAILURE';
export const CLEAR_DELIVERY_FAILURE = 'CLEAR_DELIVERY_FAILURE';
export const SET_DELIVERY_TOKEN = 'SET_DELIVERY_TOKEN';
export const SET_DOWNLOAD_ID = 'SET_DOWNLOAD_ID';
export const SET_LANG = 'SET_LANG';
export const SET_USER_LANG = 'SET_USER_LANG';
export const SET_ABUSED = 'SET_ABUSED';
export const SET_MEDIA_INFO = 'SET_MEDIA_INFO';
export const SET_PLAYER_TIME = 'SET_PLAYER_TIME';
export const SET_CURRENT_DIR_FILE_STATES = 'SET_CURRENT_DIR_FILE_STATES';
export const SET_FILE_STATE = 'SET_FILE_STATE';
export const SET_RECENT_TORRENTS = 'SET_RECENT_TORRENTS';
export const SET_RECENT_TORRENT = 'SET_RECENT_TORRENT';
export const SET_AUDIO_SETTING = 'SET_AUDIO_SETTING';
export const SET_SUBTITLE_SETTING = 'SET_SUBTITLE_SETTING';
export const DROP_AUDIO_SETTING = 'DROP_AUDIO_SETTING';
export const DROP_SUBTITLE_SETTING = 'DROP_SUBTITLE_SETTING';
export const SET_ACTION = 'SET_ACTION';
export const SET_DOWNLOAD_PATH = 'SET_DOWNLOAD_PATH';
export const SET_ADBLOCK_ENABLED = 'SET_ADBLOCK_ENABLED';
export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const SET_THEME = 'SET_THEME';
export const SET_ID = 'SET_ID';
export const SET_EXTERNAL_FEATURES = 'SET_EXTERNAL_FEATURES';
export const SET_EXTERNAL_SUBTITLES = 'SET_EXTERNAL_SUBTITLES';
export const SET_ATTACHED_TRACKS = 'SET_ATTACHED_TRACKS';
export const SET_EXTERNAL_POSTER = 'SET_EXTERNAL_POSTER';
export const SET_EXTERNAL_CONTROLS = 'SET_EXTERNAL_CONTROLS';
export const SET_AUTOPLAY = 'SET_AUTOPLAY';
export const SET_CAPTION = 'SET_CAPTION';
export const SET_PLAYER_CONTROLS_SHOWN = 'SET_PLAYER_CONTROLS_SHOWN';
export const SET_PLAYER_FULLSCREEN = 'SET_PLAYER_FULLSCREEN';
export const SET_HEADER = 'SET_HEADER';
export const SET_EXTERNAL_TITLE = 'SET_EXTERNAL_TITLE';
export const SET_WIDTH = 'SET_WIDTH';
export const SET_HEIGHT = 'SET_HEIGHT';
export const SET_IMDBID = 'SET_IMDBID';
export const SET_COMPLETED_PIECES = 'SET_COMPLETED_PIECES';
export const CHANGE_CAPTION_SIZE = 'SET_CAPTION_SIZE';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_ADS_INJECTED = 'SET_ADS_INJECTED';
export const SET_PLAYER_NATIVE_FULLSCREEN = 'SET_PLAYER_NATIVE_FULLSCREEN';
export const SET_NEW_POSITION = 'SET_NEW_POSITION';
export const SET_SEEDER = 'SET_SEEDER';
export const SET_ZIP_DOWNLOAD = 'SET_ZIP_DOWNLOAD';
export const SET_LAST_TIME = 'SET_LAST_TIME';
export const SET_FIRST_PLAY = 'SET_FIRST_PLAY';
export const SET_IS_CACHED = 'SET_IS_CACHED';
export const SET_NAME = 'SET_NAME';
export const SET_DOWNLOAD_STAT = 'SET_DOWNLOAD_STAT';
export const DROP_DOWNLOAD_STAT = 'DROP_DOWNLOAD_STAT';
export const SET_DOWNLOAD_TYPE = 'SET_DOWNLOAD_TYPE';
export const UPDATE_WINDOW_WIDTH = 'UPDATE_WINDOW_WIDTH';
export const SET_INITED = 'SET_INITED';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_API_UNREACHABLE = 'SET_API_UNREACHABLE';
